<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      transition="dialog-transition"
      max-width="400"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
    >
      <v-card>
        <div class="primary white--text">
          <v-card-title>
            แก้ไขข้อมูล
            <v-btn icon class="ml-auto white--text" @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </div>
        <v-divider></v-divider>
        <v-card-text>
          <div>
            <v-row align="center" class="pa-0 ma-0">
              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="4">Condition</v-col>
                  <v-col cols="8">
                    <b>
                      <v-badge :color="color" inline left dot></v-badge>
                      {{ condition.name }}
                    </b>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="4">Min (%)</v-col>
                  <v-col cols="8">
                    <v-select
                      v-model="editForm.min"
                      :items="selection"
                      class="right-input"
                      type="number"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row align="center">
                  <v-col cols="4">Max (%)</v-col>
                  <v-col cols="8">
                    <v-select
                      v-model="editForm.max"
                      :items="selection"
                      class="right-input"
                      type="number"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="green white--text"
            elevation="0"
            style="width: 140px;"
            @click="confirm()"
          >
            บันทึก
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-model="isConfirmEditDialog"
      :dialog-title="'ยืนยันการแก้ไขข้อมูล ?'"
      @confirm="edit()"
      @cancel="closeConfirm()"
    />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/dialog/confirmDialog.vue'
export default {
  components: {
    ConfirmDialog
  },
  props: {
    value: Boolean,
    color: String,
    condition: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    value (newVal, oldVal) {
      console.log(this.condition)
      this.editForm = { ...this.condition }
      this.editForm.min = parseInt(this.editForm.min) * 10
      this.editForm.max = parseInt(this.editForm.max) * 10
      this.dialog = newVal
    }
  },
  data: () => ({
    dialog: false,
    editForm: {},
    isConfirmEditDialog: false,
    selection: Array.from({ length: 11 }, (_, i) => (i) * 10)
  }),
  methods: {
    close () {
      this.$emit('close')
    },
    closeConfirm () {
      this.isConfirmEditDialog = false
    },
    confirm () {
      this.isConfirmEditDialog = true
    },
    async edit () {
      const payload = { ...this.editForm }
      delete payload.color
      console.log(payload)
      payload.min = parseInt(payload.min) / 10
      payload.max = parseInt(payload.max) / 10
      const result = await this.$store.dispatch('setting/setSettingSr', payload)
      console.log(result)
      if (result) {
        this.closeConfirm()
        this.$emit('save')
      }
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
