<template>
  <div class="pa-4">
    <h2 class="primary--text font-weight-regular">ตั้งค่า : S.R.</h2>
    <v-card class="primary-border mt-4" outlined>
      <v-card-text class="ma-0 my__v-card-text pa-4">
        <div>
          <v-simple-table>
            <template>
              <thead>
                <tr class="primary">
                  <th class="white--text text-center">Min</th>
                  <th class="white--text">Condition</th>
                  <th class="white--text text-center">Max</th>
                  <th></th>
                </tr>
              </thead>
              <tr v-if="isLoading" class="pa-0 ma-0">
                <td colspan="4" class="pa-0 ma-0">
                  <v-progress-linear indeterminate></v-progress-linear>
                </td>
              </tr>
              <tbody>
                <tr
                  v-for="data in srTableData"
                  :key="data.id"
                  :class="$store.getters.manage_settings ? 'pointer-hover' : ''"
                  @click="
                    $store.getters.manage_settings && openEditSrDialog(data)
                  "
                >
                  <td class="text-center">
                    {{ parseInt(data.min) * 10 }} &le;
                  </td>
                  <td>
                    <v-badge :color="data.color" inline left dot></v-badge>
                    {{ data.name }}
                  </td>
                  <td class="text-center">
                    {{ data.id === '3' ? '&le;' : '&lt;' }}
                    {{ parseInt(data.max) * 10 }}
                  </td>
                  <td class="text-center" width="1%">
                    <v-icon v-if="$store.getters.manage_settings">
                      mdi-pencil-outline
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
    <EditSrDialog
      v-model="editSrDialog"
      :color="color"
      :condition="condition"
      @save="getSettingSr"
      @close="editSrDialog = false"
    />
  </div>
</template>

<script>
import EditSrDialog from './dialog/EditSrDialog.vue'
export default {
  components: {
    EditSrDialog
  },
  data: () => ({
    srTableData: [],
    editSrDialog: false,
    condition: {},
    color: null,
    colors: ['#F2453D', '#FACE44', '#93C13B'],
    isLoading: false
  }),
  mounted () {
    this.getSettingSr()
  },
  methods: {
    openEditSrDialog (data) {
      this.condition = data
      this.color = data.color
      this.editSrDialog = true
    },
    async getSettingSr () {
      this.isLoading = true
      const srTableData = await this.$store.dispatch('setting/getSettingSr')
      srTableData.forEach((sr) => {
        sr.color =
          sr.id === '1'
            ? this.colors[0]
            : sr.id === '2'
              ? this.colors[1]
              : this.colors[2]
      })
      this.isLoading = false
      this.editSrDialog = false
      this.srTableData = srTableData
    }
  }
}
</script>

<style scoped>
.primary-border {
  border: 1px solid #c22d47;
}
</style>
